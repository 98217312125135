import React, { useEffect } from "react";
import { usePrismicPreview } from "gatsby-source-prismic";
import { useStaticQuery, graphql, navigate } from "gatsby";
import UsePreviewDataStoreContainer from "../react/common/hooks/stores/usePreviewDataStore";
import _get from "lodash.get";
import { Providers } from "../react/common/layouts/Layout";

const PreviewPageDisplay = ({ location }) => {
  const [_, setPreviewData] = UsePreviewDataStoreContainer.useContainer();
  const data = useStaticQuery(graphql`
    {
      allPrismicPost {
        nodes {
          uid
        }
      }
      allPrismicPage {
        nodes {
          uid
        }
      }
      allPrismicFaqs {
        nodes {
          uid
        }
      }
      allPrismicHomepage {
        nodes {
          uid
        }
      }
      ...MainNavigation
    }
  `);

  const pageUids = data.allPrismicPage.nodes.map(node => node.uid);
  const postUids = data.allPrismicPost.nodes.map(node => node.uid);
  const faqUids = data.allPrismicFaqs.nodes.map(node => node.uid);
  const homepageUids = data.allPrismicHomepage.nodes.map(node => node.uid);
  const UIDs = [...pageUids, ...postUids, ...faqUids, ...homepageUids];

  const pathResolver = () => doc => {
    const previewedUID = doc.uid;
    if (UIDs.includes(previewedUID)) {
      return previewedUID;
    } else {
      return "/unpublishedPreview";
    }
  };

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: "pws-website",
    pathResolver
  });
  useEffect(() => {
    if (previewData && path) {
      setPreviewData({
        pageData: previewData,
        bundledData: {
          mainNavigation: _get(data, "mainNavigation.edges[0].node.data")
        }
      });
      if (path === "homepage") {
        setTimeout(() => {
          return navigate("/");
        }, 1000);
      } else {
        setTimeout(() => {
          return navigate(`/${path}`);
        }, 1000);
      }
    }
  }, [path, previewData]);

  return <>Loading Preview...</>;
};

function PreviewPage(props) {
  return (
    <Providers>
      <PreviewPageDisplay {...props} />
    </Providers>
  );
}
export default PreviewPage;
